import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react';

import StructuredData from '../src/components/CustomComponents/Tools/StructuredData';

import './scss/style.scss'

// In your React component or App.js
import './global.css'; // Replace with the path to your global CSS file
import SetuserLevel from './components/CustomComponents/reducerComponents/SetuserLever';


//--------------------------------------------------------------------------------------





const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"> </div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Home = React.lazy(() => import('./views/home/Home'))

const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const SuperAdmin = React.lazy(() => import('./views/pages/SuperAdmin/SuperAdmin'))

const myStructuredData = {
  "@context": "https://my.seylanehsabz.ir",
  "@type": "WebPage",
  "name": "باشگاه مشتریان",
  "description": "باشگاه مشتریان و امتیازات سیلانه سبز. کدکس، کامان، میسویک، دافی، نینو",
  "image": "https://my.seylanehsabz.ir/logo192.png", // URL of the image
};
//=============================================================================================
//=============================================================================================
//=============================================================================================
class App extends Component {
  
  render() {
    return (
      <HashRouter>
        <StructuredData data={myStructuredData}></StructuredData>
        <Suspense fallback={loading}>
          <Routes>
            {/* <Route exact path="/login" name="Login Page" element={<Login />} /> */}
            {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} /> 

            {/* <Route exact path="/SuperAdmin" name="SuperAdmin" element={<SuperAdmin />} /> */}
          </Routes>
        </Suspense>
        <div style={{textAlign:'center'}}>
        
        {/* <SetuserLevel/> */}
        
        </div>
      </HashRouter>
    )
  }
}

export default App
