//import loading from "../../assets/images/loading.svg";
import loading from "../../Images/Logos/seylaneh.png";

import React, { useState } from 'react';
import {  CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader   from "react-spinners/PulseLoader"

function Loading( props) {

  return (
   <div style={{textAlign:'center',maxHeight:'50px'}}>
     <img
        src={loading}
        alt="سیلانه سبز من"
        width={props.width===undefined?'90%':props.width}
        height='168px'
      />
<PulseLoader  
color={'#36d7b7'}
loading={true}
//cssOverride={override}
size={10}
aria-label="Loading Spinner"
data-testid="loader"

/>
</div>
  );
}

export default Loading;


// const Loading = () => (
//   <div className="spinner">
//     <img src={loading} alt="Loading" />
//   </div>

// );

// export default Loading;
