const initState = {
    gridData: [],
    ProductGroupSums: [],
  };
  
  const AdminDataReducer = (state = initState, action) => {
    switch (action.type) {
      case 'GridData':
        return {
          ...state,
          gridData: action.gridData,
        };
  
      case 'ProductGroupSums':
        return {
          ...state,
          ProductGroupSums: action.ProductGroupSums,
        };
  
      default:
        return state;
    }
  };
  
  export default AdminDataReducer;
  