import {connect} from 'react-redux';
//==============================================================================================================================
//=====================================CURRENT USER====================================================================
//=====================================CURRENT USER=============================================================================
//=====================================CURRENT USER=============================================================================
export const SetuploadedPhotos=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'uploadedPhotos',
        uploadedPhotos : data
    };
}
export const SetcheckedPhotos=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'checkedPhotos',
        checkedPhotos : data
    };
}
export const SetimagesScore=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'imagesScore',
        imagesScore : data
    };
}
export const SetsaleScore=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'saleScore',
        saleScore : data
    };
}
export const SettotalScore=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'totalScore',
        totalScore : data
    };
}
export const SetcurrentScore=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'currentScore',
        currentScore : data
    };
}
export const SetusedScore=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'usedScore',
        usedScore : data
    };
}

export const SetUserScores=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'UserScores',
        UserScores : data
    };
}

export const SetUserAccessLevel=(data)=>{
    //console.log('SetUserAccessLevel index',data);
    return{
        type:'UserAccessLevel',
        UserAccessLevel : data
    };
}
export const SetProductGroup=(data=null)=>{
    return{
        type:'ProductGroup',
        ProductGroup : data
    };
}
export const SetProductGroupGrade=(data=null)=>{
    return{
        type:'ProductGroupGrade',
        ProductGroupGrade : data
    };
}
export const SetProductGroupValue=(data=null)=>{
    return{
        type:'ProductGroupValue',
        ProductGroupValue : data
    };
}
export const SetYearlySale=(data=null)=>{
    return{
        type:'YearlySale',
        YearlySale : data
    };
}
export const SetYearlyGrade=(data=null)=>{
    return{
        type:'YearlyGrade',
        YearlyGrade : data
    };
}
export const SetStoreGrade=(data=null)=>{
    return{
        type:'StoreGrade',
        StoreGrade : data
    };
}

export const SetUser=(data=null)=>{
    return{
        type:'User',
        User : data
    };
}
export const SetsetsubmitedValue=(data=null)=>{
    return{
        type:'setsubmitedValue',
        setsubmitedValue : data
    };
}

export const SetuserProducts=(data=[])=>{
    return{
        type:'userProducts',
        userProducts : data
    };
}

export const SetallProducts=(data=[])=>{
    return{
        type:'allProducts',
        allProducts : data
    };
}
export const SetpointLevel=(data=[])=>{
    return{
        type:'pointLevel',
        pointLevel : data
    };
}

export const SetisLoadingImages=(data=false)=>{
    return{
        type:'isLoadingImages',
        isLoadingImages : data
    };
}
export const SetisLoadingSales=(data=false)=>{
    return{
        type:'isLoadingSales',
        isLoadingSales : data
    };
}
export const SetisLoadingUsersImages=(data=false)=>{
    return{
        type:'isLoadingUsersImages',
        isLoadingUsersImages : data
    };
}

export const SetselectedEditUser=(data='')=>{
    return{
        type:'selectedEditUser',
        selectedEditUser : data
    };
}

export const SetselectedMapLocation=(data=[])=>{
    return{
        type:'selectedMapLocation',
        selectedMapLocation : data
    };
}

export const SetToken=(data='')=>{
    return{
        type:'Token',
        Token : data
    };
}
export const SetisAuthenticated=(data='')=>{
    return{
        type:'isAuthenticated',
        isAuthenticated : data
    };
}


//==============================================================================================================================
//==============================================================================================================================
//==============================================================================================================================
//==============================================================================================================================
export const SetViewType=(data=null)=>{
    //console.log('AddJson',mydata)
    return{
        type:'ViewType',
        ViewType : data
    };

}

export const SetGridData=(data=null)=>{
    //console.log('AddJson',mydata)
    return{
        type:'GridData',
        GridData : data
    };

}

//==============================================ADMIN DATA====================================================
//==============================================ADMIN DATA====================================================
//==============================================ADMIN DATA====================================================
//==============================================ADMIN DATA====================================================

export const SetProductGroupSums=(data=null)=>{
    //console.log('AddJson',mydata)
    return{
        type:'ProductGroupSums',
        ProductGroupSums : data
    };

}

//==============================================Page State====================================================
//==============================================Page State====================================================
//==============================================Page State====================================================
//==============================================Page State====================================================

export const SetsidebarIsOpen=(data=null)=>{
    //console.log('AddJson',mydata)
    return{
        type:'sidebarIsOpen',
        sidebarIsOpen : data
    };

}



//==============================================Set Users====================================================
//==============================================Set Users====================================================
//==============================================Set Users====================================================
//==============================================Set Users====================================================

export const SetUsers=(data=null)=>{
    //console.log('AddJson',mydata)
    return{
        type:'Users',
        Users : data
    };

}