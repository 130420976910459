import GridDataReducer from "./GridData";
import ViewStateReducer from "./ViewState";
import CurrentUserReducer from "./CurrentUser";
import AdminDataReducer from "./AdminData";
import PageStateeducer from "./PageState";
import UsersReducer from "./Users";


import { combineReducers } from 'redux';

const allReducers=combineReducers({
    GridData:GridDataReducer,
    ViewState:ViewStateReducer,
    CurrentUser:CurrentUserReducer,
    AdminData:AdminDataReducer,
    PageState:PageStateeducer,
    Users:UsersReducer
});

export default allReducers;