const initState = {
    ViewType: 'Price',
  };
  
  const ViewStateReducer = (state = initState, action) => {
    switch (action.type) {
      case 'ViewType':
        return {
          ...state,
          ViewType: action.ViewType,
        };
  
      default:
        return state;
    }
  };
  
  export default ViewStateReducer;
  