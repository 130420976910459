import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import history from "./utils/history";
import { Provider } from 'react-redux'
//import store from './store'
// import { Auth0Provider } from "@auth0/auth0-react"
import { getConfig } from "./config"
import { configureStore } from '@reduxjs/toolkit'
import allReducers from './reducers/index';
//import './index.css'

import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reducers/Store';
//------------------

//--------------------


// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };
const config = getConfig();
const mystore = configureStore({ reducer: allReducers })

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        //console.log('Service Worker registered:', registration);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

//persistor.purge();
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    {/* <Auth0Provider
      {...providerConfig}
      languageDictionary={languageDictionary}
      scope="openid profile email read:current_user update:current_user_metadata"

    > */}
      <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
    {/* </Auth0Provider> */}
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
