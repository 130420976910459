const initState = {
    sidebarIsOpen:true,
  };
  
  const PageStateeducer = (state = initState, action) => {
    switch (action.type) {
      case 'sidebarIsOpen':
        return {
          ...state,
          sidebarIsOpen: action.sidebarIsOpen,
        };
  
      default:
        return state;
    }
  };
  
  export default PageStateeducer;
  