const initState = {
    Users: [],
  };
  
  const UsersReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Users':
        return {
          ...state,
          Users: action.Users,
        };
  
      default:
        return state;
    }
  };
  
  export default UsersReducer;
  