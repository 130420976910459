import Loading from "../../CustomComponents/Loading";
import React,{ useState ,useEffect} from "react";

import {useDispatch, useSelector} from 'react-redux'
import { getConfig } from "../../../config";

// import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios'
import {SetUserAccessLevel,SetUser,SetProductGroupSums} from "../../../actions/index"
import { connect } from 'react-redux';


const SetuserLevel = (props) => {
  //const { isLoading, error } = useAuth0();
  const myReducerState = useSelector(state => state);


  const config = getConfig();
  // const {
  //   user,
  //   isAuthenticated,
  //   loginWithRedirect,
  //   logout,
  // } = useAuth0();
  const [appMetadata, setAppMetadata] = useState(null);
  const [userLevel, setuserLevel] = useState(0);
  const dispatch= useDispatch();
  const dispatch2= useDispatch();




  // useEffect(() => {
    
  //   if (isAuthenticated==true) {
  //     //console.log('isAuthenticated',isAuthenticated)
  //     axios
  //       .get(`https://`+config.domain+`/api/v2/users/${user.sub}`, {
  //         headers: {
  //           Authorization: `Bearer `+config.Token,
  //         },
  //       })
  //       .then((response) => {
  //         //console.log('token 0:',response.data.user_metadata)
  //         setAppMetadata(response.data.app_metadata);
  //         //console.log('token',user,config);
          
  //          //console.log('setuserLevel0',myReducerState.CurrentUser.UserAccessLevel,userLevel)
  //          try {
  //           dispatch(SetUserAccessLevel(response.data.user_metadata.Level));
  //           //dispatch(SetProductGroupSums(1));
  //           dispatch(SetUser({'User':user,'Metadata':response.data.user_metadata}));
            
  //          }catch(error)
  //          {
  //           console.log(error);
  //          }
           
                        
  //          setuserLevel(myReducerState.CurrentUser.UserAccessLevel);
  //          //console.log('setuserLevel',userLevel)

          
  //       })
  //       .catch((error) => {
  //         try{
  //           console.log('Error fetching app_metadata:', error);
  //         }catch(e){

  //         }
          
  //       });
  //   }else{
  //     dispatch(SetUserAccessLevel(0))
  //   }
  // }, [isAuthenticated]);


  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }

  // if (isLoading) {
  //   return <Loading  width='100px' />;
  // }
  return (
  
      <div>

      </div>
  );
};

function mapStateToProps(state, ownProps) {
	//console.log('PlayeCell Render',state);
	return { allStateData: state }
  }
  //,SetUser,SetProductGroupSums
const mapDispatchToProps = dispatch => {
	return {

	  SetUserAccessLevel: (todo) => {		  
		dispatch(SetUserAccessLevel(todo))
	  },
    SetProductGroupSums: (todo) => {		  
      dispatch(SetProductGroupSums(todo))
	}
  }
}

export default  connect(mapStateToProps,mapDispatchToProps) (SetuserLevel);