
const initState={
    UserAccessLevel:0,
    ProductGroup:[],
    ProductGroupGrade:[],
    ProductGroupValue:[],
    YearlySale:0,
    YearlyGrade:0,
    StoreGrade:0,
    User:[],
    setsubmitedValue:0,
    imagesScore:0,
    saleScore:0,
    totalScore:0,
    usedScore:0,
    currentScore:0,
    uploadedPhotos:0,
    checkedPhotos:0,
    allProducts:[],
    userProducts:[],
    pointLevel:1,
    //pointRanges:[0,1500,4000,7000,13000,20000],
    pointRanges:[0,250,1250,5000],
    isLoadingImages:true,
    isLoadingSales:true,
    isLoadingUsersImages:true,
    selectedMapLocation: [35.7009,51.3912],
    selectedEditUser:'',
    Token:'',
    isAuthenticated:false,
    UserScores:[]
}
const CurrentUserReducer=(state=initState,action)=>{

    switch(action.type)
    {
      case 'isAuthenticated':
        return {
          ...state,
          isAuthenticated: action.isAuthenticated,
        };
      case 'Token':
        return {
          ...state,
          Token: action.Token,
        };

      case 'uploadedPhotos':
        return {
          ...state,
          uploadedPhotos: action.uploadedPhotos,
        };
      case 'checkedPhotos':
        return {
          ...state,
          checkedPhotos: action.checkedPhotos,
        };
      case 'imagesScore':
        return {
          ...state,
          imagesScore: action.imagesScore,
        };

      case 'saleScore':
        return {
          ...state,
          saleScore: action.saleScore,
        };

      case 'totalScore':
        return {
          ...state,
          totalScore: action.totalScore,
        };
      case 'usedScore':
        return {
          ...state,
          usedScore: action.usedScore,
        };
      case 'UserScores':
        return {
          ...state,
          UserScores: action.UserScores,
        };        

      case 'currentScore':
      return {
        ...state,
        currentScore: action.currentScore,
      };
        

        case 'UserAccessLevel':
      return {
        ...state,
        UserAccessLevel: action.UserAccessLevel,
      };

    case 'ProductGroup':
      return {
        ...state,
        ProductGroup: action.ProductGroup,
      };

    case 'ProductGroupGrade':
      return {
        ...state,
        ProductGroupGrade: action.ProductGroupGrade,
      };

    case 'ProductGroupValue':
      return {
        ...state,
        ProductGroupValue: action.ProductGroupValue,
      };

    case 'YearlySale':
      return {
        ...state,
        YearlySale: action.YearlySale,
      };

    case 'YearlyGrade':
      return {
        ...state,
        YearlyGrade: action.YearlyGrade,
      };

    case 'StoreGrade':
      return {
        ...state,
        StoreGrade: action.StoreGrade,
      };

    case 'User':
      //console.log('in reducer User', action);
      return {
        ...state,
        User: action.User,
      };
      case 'setsubmitedValue':
        //console.log('in reducer User', action);
        return {
          ...state,
          setsubmitedValue: action.setsubmitedValue,
        };
      case 'userProducts':
        return {
          ...state,
          userProducts: action.userProducts,
        };
      case 'allProducts':
        return {
          ...state,
          allProducts: action.allProducts,
        };
      case 'pointLevel':
        return {
          ...state,
          pointLevel: action.pointLevel,
        };
      case 'isLoadingSales':
        return {
          ...state,
          isLoadingSales: action.isLoadingSales,
        };
      case 'isLoadingImages':
    return {
      ...state,
      isLoadingImages: action.isLoadingImages,
    };

    case 'selectedMapLocation':
    return {
      ...state,
      selectedMapLocation: action.selectedMapLocation,
    };
    
    case 'selectedEditUser':
    return {
      ...state,
      selectedEditUser: action.selectedEditUser,
    };

    return {
      ...state,
      isLoadingUsersImages: action.isLoadingUsersImages,
    };
        
        
        
    default:
      return state;
  
    }
    
}
export default CurrentUserReducer;
